<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Данные коллекции</h2>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6-desktop">
        <p class="subtitle is-5">Выберите коллекцию</p>

        <v-select
          outlined
          dense
          hide-details
          clearable
          :items="collections"
          item-value="id"
          item-text="name"
          label="Коллекция"
          v-model="selectedCollection"
          @input="getSelectedCollection"
        />
      </div>
    </div>

    <div class="columns"
         v-if="selectedCollection">
      <div class="column">
        <b-button
          type="is-primary"
          expanded
          @click="save"
          class="mb-4">
          Сохранить
        </b-button>
      </div>

      <div class="column">
        <b-button
          type="is-danger"
          expanded
          :disabled="collection.data && this.collection.data.length === 0"
          @click="saveToFireStore"
          class="mb-4">
          Сохранить и залить
        </b-button>
      </div>
    </div>

    <p
      v-if="selectedCollection && collection.data && collection.data.length === 0"
      class="error--text">
      Без таблиц коллекция не может существовать
    </p>

    <draggable
      class="pa-0"
      v-if="selectedCollection && collection.data && collection.data.length > 0"
      v-model="collection.data"
      group="documents"
      v-bind="dragOptions"
      @end="updateDocumentIds"
    >
      <transition-group type="transition">
        <div
          v-for="(document, index) in collection.data"
          :key="`${selectedCollection}_${index}`"
          class="mb-6 draggable-item animate card pa-6"
        >
          <div class="columns">
            <div class="column is-1">
              <div class="mt-5">
                <i class="icon is-medium mdi mdi-dark mdi-menu" style="font-size: 24px;"></i>
              </div>
            </div>

            <div class="column is-1" v-if="document.json.id">
              <b-field label="ID">
                <b-input
                  class="mt-3"
                  size="is-small"
                  v-model="document.json.id"
                  disabled>
                </b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Название">
                <b-input
                  class="mt-3"
                  @click.stop
                  size="is-small"
                  v-model="document.name">
                </b-input>
              </b-field>
            </div>

            <div class="column is-2"
                 style="display: flex;
                          align-items: center;">
              <b-tooltip label="Удалить">
                <b-button
                  @click="removeDocument(index)"
                  class="delete_icon mt-5">
                  <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M4 7l16 0"></path>
                    <path d="M10 11l0 6"></path>
                    <path d="M14 11l0 6"></path>
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                  </svg>
                </b-button>
              </b-tooltip>

              <b-tooltip label="Дублировать">
                <b-button @click="duplicateDocument(index)" class="edit_icon mt-5">
                  <svg
                    viewBox="0 0 800 800"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M308.214 216.929V171.286C308.214 128.253 308.214 106.737 321.583 93.3683C334.952 80 356.468 80 399.5 80H627.714C670.746 80 692.262 80 705.631 93.3683C719 106.737 719 128.253 719 171.286V399.5C719 442.532 719 464.048 705.631 477.417C692.262 490.786 670.746 490.786 627.714 490.786H582.071M171.286 719H399.5C442.532 719 464.048 719 477.417 705.631C490.786 692.262 490.786 670.746 490.786 627.714V399.5C490.786 356.468 490.786 334.952 477.417 321.583C464.048 308.214 442.532 308.214 399.5 308.214H171.286C128.253 308.214 106.737 308.214 93.3683 321.583C80 334.952 80 356.468 80 399.5V627.714C80 670.746 80 692.262 93.3683 705.631C106.737 719 128.253 719 171.286 719Z" stroke-width="50" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </b-button>
              </b-tooltip>
            </div>
          </div>
          <div class="columns">
            <div class="column is-1"></div>
            <div class="column">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-0 py-0">
                    Дополнительная информация
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="columns">
                      <div class="column">

                        <div
                          style="text-align: center;"
                          v-if="hasUri">
                          <img
                            :src="document.json['uri']"
                            class="prewiew-img"
                          />

                          <p class="error--text mb-6">Если вы не видите тут картинку - ссылка неверная</p>
                        </div>

                        <div class="fixed-grid">
                          <div class="grid has-2-columns">
                            <div
                              v-for="(value, key) in document.json"
                              v-if="key !== 'id'"
                              :key="key"
                              class="cell"
                            >
                              <b-field :label="key">
                                <template v-if="getFieldType(key) === 'string'">
                                  <b-input
                                    size="is-small"
                                    v-model="document.json[key]"
                                  ></b-input>
                                </template>

                                <template v-else-if="getFieldType(key) === 'number'">
                                  <b-input
                                    size="is-small"
                                    type="number"
                                    v-model.number="document.json[key]"
                                  ></b-input>
                                </template>

                                <template v-else-if="getFieldType(key) === 'bool'">
                                  <b-checkbox
                                    size="is-small"
                                    v-model="document.json[key]"
                                  ></b-checkbox>
                                </template>

                                <template v-else-if="getFieldType(key) === 'timestamp'">
                                  <b-input
                                    size="is-small"
                                    type="datetime-local"
                                    v-model="document.json[key]"
                                  ></b-input>
                                </template>

                                <template v-else>
                                  <b-input
                                    size="is-small"
                                    v-model="document.json[key]"
                                  ></b-input> <!-- по умолчанию строка -->
                                </template>
                              </b-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </transition-group>
    </draggable>

    <b-button
      v-if="selectedCollection"
      type="is-primary"
      expanded
      @click="addDocument"
      class="mb-4">
      Добавить карточку
    </b-button>

    <OverlayLoader :show="isLoading"></OverlayLoader>

  </div>
</template>

<script>
import OverlayLoader from "@/app/shared/components/app-loader/Loader";
import draggable from 'vuedraggable';

export default {
  name: "CollectionsList",
  components: {
    OverlayLoader,
    draggable
  },
  data() {
    return {
      selectedCollection: null,
    };
  },
  created() {
    this.getCollections();
  },
  computed: {
    hasUri() {
      return this.collection.fields.some(field => field.name === 'uri');
    },
    hasId() {
      return this.collection.fields.some(field => field.name === 'id' && field.type === 'number');
    },
    collections() {
      return this.$store.getters.GET_COLLECTIONS;
    },
    dragOptions() {
      return {
        animation: 200,
        scroll: true,
        scrollSensitivity: 100,
        scrollSpeed: 10,
      };
    },
    collection() {
      return this.$store.getters.GET_DATA;
    },
    isLoading: {
      get() {
        return this.$store.getters.IS_DATA_LOADING;
      },
      set(value) {
        this.$store.commit("SET_DATA_LOADING", value);
      },
    },
    idFirebase() {
      return this.$route.params.idFirebase;
    },
  },
  methods: {
    getFieldType(key) {
      const field = this.collection.fields.find(f => f.name === key);
      return field ? field.type : 'string'; // Если не найдено, то тип по умолчанию - строка
    },
    // Функция для дублирования документа
    duplicateDocument(index) {
      const originalDocument = this.collection.data[index];

      // Создаём копию документа
      const duplicatedDocument = {
        name: `${originalDocument.name} копия`,
        json: { ...originalDocument.json }
      };

      if (this.hasId) {
        // Находим максимальное значение `id` среди всех документов
        let maxId = this.collection.data.reduce((max, doc) => {
          return doc.json && typeof doc.json.id === 'number' ? Math.max(max, doc.json.id) : max;
        }, 1); // Начинаем с 1, если документов пока нет или у них нет id

        // Устанавливаем новый уникальный `id` для дубликата
        duplicatedDocument.json.id = maxId + 1;
      }

      // Добавляем дубликат документа в конец коллекции
      this.collection.data.push(duplicatedDocument);
    },
    addDocument() {
      // Создаём пустой объект с ключами из `collection.fields` и пустыми строками в качестве значений
      let newDocument = {
        name: '',
        json: {}
      };

      // Заполняем поля пустыми значениями
      this.collection.fields.forEach(field => {
        switch (field.type) {
          case 'string':
            newDocument.json[field.name] = '';
            break;
          case 'number':
            newDocument.json[field.name] = 0;
            break;
          case 'bool':
            newDocument.json[field.name] = false;
            break;
          case 'timestamp':
            newDocument.json[field.name] = new Date().toISOString();
            break;
          default:
            newDocument.json[field.name] = '';
        }
      });

      if (this.hasId) {
        // Находим максимальное значение `id` в текущих документах
        let maxId = this.collection.data.reduce((max, doc) => {
          return doc.json && typeof doc.json.id === 'number' ? Math.max(max, doc.json.id) : max;
        }, 1); // Начинаем с 1, если документов пока нет или у них нет id

        // Устанавливаем `id` для нового документа
        newDocument.json.id = maxId + 1;
      }

      // Добавляем новую карточку в коллекцию
      this.collection.data.push(newDocument);
    },
    updateDocumentIds() {
      // Проверяем, есть ли хотя бы один документ с `id`
      const hasIds = this.collection.data.some(document => document.json.id);

      if (hasIds) {
        // Перезаписываем только если `id` присутствуют
        this.collection.data.forEach((document, index) => {
          if (document.json.id) {
            // обновляем `id` на основе индекса
            document.json.id = index + 1;
          }
        });
      }
    },
    removeDocument(index) {
      this.collection.data.splice(index, 1);
      this.updateDocumentIds();
    },
    getCollections() {
      this.$store.dispatch("GET_COLLECTIONS", this.idFirebase).catch(() => {
        this.$toast.error("Ошибка при получении данных");
      });
    },
    getSelectedCollection() {
      if (this.selectedCollection) {
        let data = {
          idFirebase: this.idFirebase,
          idCollection: this.selectedCollection
        }
        this.$store.dispatch("GET_COLLECTION_BY_ID", data).catch(() => {
          this.$toast.error("Ошибка при получении данных");
        });
      }
    },
    save() {
      let data = {
        idFirebase: this.idFirebase,
        idCollection: this.collection.id,
        data: this.collection
      }
      this.$store.dispatch("SAVE_COLLECTION", data).then(() => {
        this.$toast.success("Данные сохранены");
        this.getSelectedCollection();
      }).catch(() => {
        this.$toast.error("Ошибка при сохранении данных");
      })
    },
    saveToFireStore() {
      let data = {
        idFirebase: this.idFirebase,
        idCollection: this.collection.id,
        data: this.collection
      }
      this.$store.dispatch("SAVE_TO_FIREBASE_COLLECTION", data).then(() => {
        this.$toast.success("Данные сохранены и отправлены");
        this.getSelectedCollection();
      }).catch(() => {
        this.$toast.error("Ошибка при сохранении данных");
      })
    }
  },
};
</script>

<style>
.drag-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.draggable-item {
  border: none !important;
  padding: 0px;
  background-color: #fff;
  position: relative;
}

.drag-handle {
  cursor: grab;
  position: absolute;
  top: 10px;
  right: 10px;
}

.card img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.grid {
  display: grid;
  gap: 16px;
}

.grid.has-2-columns {
  grid-template-columns: repeat(2, 1fr); /* 2 колонки */
}

.label {
  font-size: 0.8rem;
}

.label:not(:last-child) {
  margin-bottom: 0.1rem;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panel-content__wrap {
  padding: 0;
}

.v-expansion-panel-header {
  min-height: 30px;
}

.prewiew-img {
  margin-top: 8px;
  max-width: min(100%, 200px) !important;
  border-radius: 8px;
}
</style>
